import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
	static targets = [ 'countries', 'addButton', 'removeButton' ]

	addCountry() {
		this.addButtonTarget.click()
	}

	removeCountry() {
		this.removeButtonTarget.click()
	}

	disableButtons() {
		this.addButtonTarget.disabled = true
		this.removeButtonTarget.disabled = true
	}
}
