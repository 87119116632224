import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
	static targets = [ 'channelFilter', 'campaignField', 'salesCount', 'emailForm' ]

	connect() {
		this.campaignFieldState()
	}

	campaignFieldState() {
		if (this.channelFilterTarget.value === '0') {
			this.campaignFieldTarget.style.display = 'block'
		} else {
			this.campaignFieldTarget.style.display = 'none'
		}
	}

	countSales() {
		fetch('/tools/bulk_email_count', {
		    body: new FormData(this.emailFormTarget),
		    method: 'post',
		}).then(response => {
			response.text().then(text => this.salesCountTarget.textContent = text)
		})
	}

	emailFormTargetConnected() {
		document.addEventListener('turbo:submit-end', (event) => {
			if (event.detail.formSubmission.result.success) {
				this.emailFormTarget.reset()
				this.emailFormTarget.querySelector('trix-editor').editor.loadHTML('')
				document.getElementById('bulk_email').open = false
			}
		})
	}
}
