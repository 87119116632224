import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
	static targets = [ 'itemPrice', 'refundTotal', 'selectAll' ]
	static values = { total: Number }
	static formatter = new Intl.NumberFormat('en-GB', {
		style: 'currency',
		currency: 'GBP',
	})

	connect() {
		this.checkBoxState()
		this.calculateRefund()
	}

	calculateRefund() {
		this.totalValue = this.itemPriceTargets.filter(item => item.checked).reduce((a, i) => a + parseInt(i.dataset.price), 0)
		this.refundTotalTarget.textContent = this.constructor.formatter.format(this.totalValue/100)
		this.checkBoxState()
	}

	selectAll(event) {
		this.itemPriceTargets.forEach(item => item.checked = event.target.checked)
		this.calculateRefund()
	}

	checkBoxState() {
		if (this.itemPriceTargets.every(item => item.checked)) {
			this.selectAllTarget.indeterminate = false
			this.selectAllTarget.checked = true
		} else if (this.itemPriceTargets.every(item => !item.checked)) {
			this.selectAllTarget.indeterminate = false
			this.selectAllTarget.checked = false
		} else {
			this.selectAllTarget.indeterminate = true
			this.selectAllTarget.checked = true
		}
	}
}
