import { Controller } from '@hotwired/stimulus'
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default class extends Controller {
	static values = {
		dayData: { type: Array, default: [0,0,0,0,0,0,0] },
		dayMax: { type: Number, default: 15 },
		breakdown: { type: Array, default: [['Created',0], ['In progress',0], ['Completed',0], ['Cancelled',0]] }
	}
	static targets = [ 'salesByDay', 'salesBreakdown' ]

	salesBreakdownTargetConnected() {
		var breakdownChart = new Chart(this.salesBreakdownTarget, {
			type: 'pie',
			data: {
				labels: this.breakdownValue.map(b => b[0]),
				datasets: [{
					data: this.breakdownValue.map(b => b[1]),
					backgroundColor: ['lightblue', 'orange', 'green', 'darkred']
				}]
			},
			options: {
				plugins: {
					legend: {
						position: 'right'
					},
					datalabels: {
						formatter: (value, ctx) => {
							let sum = 0;
							let dataArr = ctx.chart.data.datasets[0].data;
							dataArr.map(data => {
								sum += data;
							});
							let percentage = (value*100 / sum).toFixed(1)+"%";
							if (percentage == '0.0%') {
								percentage = null
							}
							return percentage;
						},
						color: '#fff',
					}
				}
			}
		})
	}

	salesByDayTargetConnected() {
		Chart.register(ChartDataLabels);
		var breakdownChart = new Chart(this.salesByDayTarget, {
			type: 'line',
			data: {
				labels: this.dayDataValue.map(d => d[0]),
				datasets: [{
					label: 'Sales',
					data: this.dayDataValue.map(d => d[1]),
					borderColor: '#530',
					backgroundColor: '#530'
				}]
			},
			options: {
				plugins: {
					legend: {
						display: false
					}
				},
				scales: {
					y: {
						beginAtZero: true,
						max: this.dayMaxValue,
						ticks: {
							stepSize: 1
						}
					}
				}
			}
		})
	}
}
