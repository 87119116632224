import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
	static targets = [ 'loader', 'success', 'error' ]

	updateStock(event) {
		this.loaderTargets.filter(loader => loader.dataset.productId == event.target.dataset.productId)[0].classList.remove('d-none')
		fetch('/products/'+event.target.dataset.productId+'.json', {
			headers: {
				'Content-Type': 'application/json',
				'X-Requested-With': 'XMLHttpRequest'
			},
			body: JSON.stringify({
				authenticity_token: document.querySelector('input[name=authenticity_token]').value,
				product: { stock: event.target.value }
			}),
			method: 'PATCH',
		}).then(response => {
			this.loaderTargets.filter(loader => loader.dataset.productId == event.target.dataset.productId)[0].classList.add('d-none')
			if (response.status == 200) {
				this.successTargets.filter(success => success.dataset.productId == event.target.dataset.productId)[0].classList.remove('d-none')
				setTimeout(() => { this.successTargets.filter(success => success.dataset.productId == event.target.dataset.productId)[0].classList.add('d-none') }, 1000)
			} else {
				this.errorTargets.filter(error => error.dataset.productId == event.target.dataset.productId)[0].classList.remove('d-none')
				setTimeout(() => { this.errorTargets.filter(error => error.dataset.productId == event.target.dataset.productId)[0].classList.add('d-none') }, 1000)
			}
		})
	}
}
