import { Controller } from '@hotwired/stimulus'
import { loadStripe } from '@stripe/stripe-js';

export default class extends Controller {
	async connect() {
		if (gon.stage != 'general_sale') {
			return
		}

		const stripe = await loadStripe(gon.stripe_key, {
			stripeAccount: gon.stripe_account,
		});
		const elements = await stripe.elements();

		var paymentRequest = stripe.paymentRequest({
			country: 'GB',
			currency: 'gbp',
			total: {
				label: gon.company,
				amount: gon.total,
			},
			requestPayerEmail: true,
			requestShipping: true,
			displayItems: gon.items,
		});

		var prButton = elements.create('paymentRequestButton', {
			paymentRequest: paymentRequest,
		});
		paymentRequest.canMakePayment().then(function(result) {
			if (result) {
				prButton.mount('#payment-request-button');
			} else {
				document.getElementById('payment-request-button').style.display = 'none';
			}
		});

		paymentRequest.on('shippingaddresschange', function(ev) {
			fetch('/preorders/shipping', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					shippingAddress: ev.shippingAddress,
					weight: gon.weight
				})
			}).then(function(response) {
				return response.json();
			}).then(function(result) {
				ev.updateWith({
					status: 'success',
					shippingOptions: result.supportedShippingOptions,
					total: {
						label: gon.company,
						amount: gon.total+result.supportedShippingOptions[0].amount
					}
				});
			});
		});

		paymentRequest.on('shippingoptionchange', function(ev) {
			ev.updateWith({
				status: 'success',
				total: {
					label: gon.company,
					amount: gon.total+ev.shippingOption.amount
				}
			});
		});

		paymentRequest.on('paymentmethod', function(ev) {
			fetch('/preorders.json', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
				},
				body: JSON.stringify({
					sale: {
						email: ev.payerEmail,
						shipping_address: {
							recipient: ev.shippingAddress.recipient,
							street:    ev.shippingAddress.addressLine[0],
							street_2:  ev.shippingAddress.addressLine[1] || "",
							city:      ev.shippingAddress.city,
							region:    ev.shippingAddress.region,
							postcode:  ev.shippingAddress.postalCode,
							country:   ev.shippingAddress.country,
						}
					},
				})
			}).then(function(response) {
				return response.json();
			}).then(function(result) {
				console.log("sale created");
				console.log(result)
				stripe.confirmCardPayment(
					result.clientSecret,
					{ payment_method: ev.paymentMethod.id },
					{ handleActions: false }
				).then(function(confirmResult) {
					if (confirmResult.error) {
						ev.complete('fail');
					} else {
						ev.complete('success');
						if (confirmResult.paymentIntent.status === "requires_action") {
							stripe.confirmCardPayment(result.clientSecret).then(function(result) {
								if (result.error) {
									console.log(result);
								} else {
									window.location.href = gon.success_url;
								}
							});
						} else {
							window.location.href = gon.success_url;
						}
					}
				});
			});
		});
	}
}
