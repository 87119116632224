import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
	static targets = [ 'completedFilter', 'markFulfilled', 'channelFilter', 'campaignField' ]

	connect() {
		this.checkBoxState()
		this.campaignFieldState()
	}

	checkBoxState() {
		if (this.markFulfilledTarget.checked) {
			this.completedFilterTarget.checked = true
			this.completedFilterTarget.disabled = true
		} else {
			this.completedFilterTarget.disabled = false
		}
	}

	campaignFieldState() {
		if (this.channelFilterTarget.value === '0') {
			this.campaignFieldTarget.style.display = 'block'
		} else {
			this.campaignFieldTarget.style.display = 'none'
		}
	}
}
