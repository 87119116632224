require("@rails/activestorage").start()
require("./channels")
import { Turbo } from "@hotwired/turbo-rails"
import Rails from "@rails/ujs"
import "./controllers"
require("trix")
//import "@rails/actiontext"

Rails.start()

Rails.delegate(document, Rails.linkDisableSelector,   "turbo:before-cache", Rails.enableElement)
Rails.delegate(document, Rails.buttonDisableSelector, "turbo:before-cache", Rails.enableElement)
Rails.delegate(document, Rails.buttonDisableSelector, "turbo:submit-end", Rails.enableElement)

Rails.delegate(document, Rails.formSubmitSelector, "turbo:submit-start", Rails.disableElement)
Rails.delegate(document, Rails.formSubmitSelector, "turbo:submit-end", Rails.enableElement)
Rails.delegate(document, Rails.formSubmitSelector, "turbo:before-cache", Rails.enableElement)

window.addEventListener("trix-file-accept", function(event) {
	event.preventDefault()
	alert("File attachment not supported!");
})

document.addEventListener("turbo:submit-start", function(event) {
	document.querySelector('turbo-frame#flash').innerHTML = null;
})
