import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
	static targets = [ 'linkField', 'linkHelp', 'linkSuccess', 'linkError' ]

	validateLink() {
		this.linkFieldTarget.classList.remove('successed', 'errored')
		this.linkHelpTarget.classList.add('v-hidden')
		this.linkSuccessTarget.classList.add('d-none')
		this.linkErrorTarget.classList.add('d-none')

		if (this.linkFieldTarget.querySelector('input').value.includes('{TRACKING_NUMBER}')) {
			this.linkFieldTarget.classList.add('successed')
			this.linkSuccessTarget.classList.remove('d-none')
		} else {
			this.linkFieldTarget.classList.add('errored')
			this.linkErrorTarget.classList.remove('d-none')
		}
	}
}
